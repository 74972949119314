<template>
  <div class="bindPhone">
    <Header title="绑定手机号" />
    <div class="main">
      <div class="phoneInputBox">
        <van-dropdown-menu class="areaBox">
          <van-dropdown-item :title="areaCode ? areaCode : '区号'" v-model="areaCode" :options="areaCodes" />
        </van-dropdown-menu>
        <van-field class="vanInput phoneInput" clearable center v-model="phoneVal" :border="false" placeholder="请输入手机号" />
      </div>
      <div class="codeInputBox">
        <div class="inputLabel">验证码</div>
        <van-field class="vanInput codeInput" center v-model="codeVal" :border="false" placeholder="请输入验证码">
          <template #button>
            <div class="loading" v-if="loading">
              <van-loading type="spinner" color="#ec8737" size="20" />
            </div>
            <div class="sendCode" v-else-if="timer">{{ timer }}S</div>
            <div class="sendCode" v-else @click="sendCode">获取验证码</div>
            <!-- <div class="sendCode" size="small" type="primary">获取验证码</div> -->
          </template>
        </van-field>
      </div>
      <div class="bindPhone" @click="mobileBind">立即绑定</div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header';
import { AreaCode } from '@/utils/areaCode.js';
import { sendCode, mobileBind } from '@/api/user';
import { Field, Loading, DropdownMenu, DropdownItem, Toast } from 'vant';
export default {
  name: 'BindPhone',
  components: {
    Header,
    [Field.name]: Field,
    [Loading.name]: Loading,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
  },
  data() {
    return {
      phoneVal: '', // 手机号
      codeVal: '', // 验证码
      areaCode: '+86', // 选中区号
      areaCodes: AreaCode, // 区号列表
      timer: 0, // 倒计时时间
      loading: false, // loading
      hasPhone: '',
    };
  },
  created() {
    this.hasPhone = this.$store.getters.userInfo.mobile;
  },
  methods: {
    // 倒计时
    countdown() {
      this.timer = 60;
      let countdown = setInterval(() => {
        this.timer--;
        if (this.timer <= 0) {
          clearInterval(countdown);
        }
      }, 1000);
    },
    // 发送验证码
    async sendCode() {
      if (!this.areaCode) {
        Toast('请选择区号');
        return;
      }
      if (!this.phoneVal) {
        Toast('请输入手机号');
        return;
      }
      this.loading = true;
      let req = {
        mobile: this.areaCode + this.phoneVal,
        type: 1, // 1-绑定手机号 2-手机号登陆
      };
      let ret = await this.$Api(sendCode, req);
      this.loading = false;
      if (ret && ret.code == 200) {
        this.countdown();
        Toast('发送成功');
      } else {
        Toast(ret.tip || '验证码发送失败');
      }
    },
    // 绑定换绑手机号
    async mobileBind() {
      if (!this.areaCode) {
        Toast('请选择区号');
        return;
      }
      if (!this.phoneVal) {
        Toast('请输入手机号');
        return;
      }
      if (!this.codeVal) {
        Toast('请输入验证码');
        return;
      }
      let req = {
        mobile: this.areaCode + this.phoneVal,
        code: this.codeVal,
      };
      this.$store.commit('app/SET_LOADING', true);
      try {
        let ret = await this.$Api(mobileBind, req);
        this.$store.commit('app/SET_LOADING', false);
        if (ret && ret.code == 200) {
          Toast('绑定成功');
          this.$store.dispatch('user/getUserInfo');
          this.$router.push({
            path: '/bindSuccess',
            query: { phone: req.mobile },
          });
        } else {
          Toast(ret.tip || '绑定失败，请稍后再试');
        }
      } catch (error) {
        this.$store.commit('app/SET_LOADING', false);
        Toast('操作失败');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bindPhone {
  .main {
    height: calc(100% - 44px);
    overflow-y: auto;
    padding: 0 20px;
    box-sizing: border-box;
    .phoneInputBox {
      height: 58px;
      border-radius: 10px;
      margin: 20px 0 16px;
      background: rgba(248, 248, 249, 0.7);
      display: flex;
      align-items: center;
      justify-content: center;
      .areaBox {
        width: 82px;
        height: 27px;
        border-right: 1px solid #e1e1ea;
        /deep/ .van-dropdown-menu__item {
          width: 82px;
        }

        /deep/ .van-dropdown-menu__bar {
          height: 100%;
          background: #00000000;
          box-shadow: none;
        }

        /deep/ .van-ellipsis {
          color: rgb(51, 51, 51);
        }

        /deep/ .van-dropdown-menu__title {
          // margin-left: 10px;
          font-size: 14px;
          color: #666666;
        }
      }
      .phoneInput {
        // width: 224px;
        height: 27px;
        padding: 0 17px;
        box-sizing: border-box;
        background: #00000000;
        /deep/ .van-field__control {
          font-size: 14px;
        }
      }
    }
    .codeInputBox {
      height: 58px;
      border-radius: 10px;
      // margin: 20px 0 16px;
      background: rgba(248, 248, 249, 0.7);
      display: flex;
      align-items: center;
      justify-content: center;
      .inputLabel {
        width: 82px;
        height: 27px;
        line-height: 27px;
        font-size: 12px;
        color: #666666;
        padding-left: 26px;
        border-right: 1px solid #e1e1ea;
      }
      .codeInput {
        height: 27px;
        padding: 0 17px;
        box-sizing: border-box;
        background: #00000000;
        /deep/ .van-field__control {
          font-size: 14px;
        }
        .sendCode {
          width: 83px;
          height: 24px;
          border-radius: 10px;
          font-size: 12px;
          text-align: center;
          color: #fff;
          background-image: linear-gradient(to right, #f74a82, #fca984);
        }
      }
    }
    .bindPhone {
      width: 258px;
      height: 38px;
      border-radius: 10px;
      margin: 40px auto;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      background-image: linear-gradient(to right, #f74a82, #fca984);
    }
  }
}
</style>
